import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mantine/core";
import { IPublicClientApplication } from "@azure/msal-browser";
import storage from "../../../utils/storage";

function handleLogout(instance: IPublicClientApplication) {
    storage.clearNavToken()
    storage.clearToken();
    storage.clearUserToken();
    
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });

}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="subtle" className="ml-auto" onClick={() => handleLogout(instance)}>Logout</Button>
    );
}