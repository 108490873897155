import { axios } from '../../../library/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from '../../../library/react-query';
import { AxiosResponse } from '../../../types';

export type updateStatusDTO = {
    status_id: string;
    order_id: string;
    
};

export const updateStatus = (data : updateStatusDTO): Promise<AxiosResponse> => {
    return axios.put(`/registration/update_status`, data)
};

type QueryFnType = typeof updateStatus;

type UseReferenceOptions = {
    data: updateStatusDTO;
    config?: QueryConfig<QueryFnType>;
};

export const useStatusUpdate = ({ data, config }: UseReferenceOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['comments', data],
        queryFn: () => updateStatus(data),
        ...config,
    });
};