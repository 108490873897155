import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import {axios} from '../axios';
import { loginRequest } from '../../config/auth/authConfig';
import { msalInstance } from '../..';

interface RequestInterceptorProps {
    children: JSX.Element,
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config: any) => {
    const account = msalInstance.getAllAccounts()[0];
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    config.headers.Authorization = bearer;

    return config;
  });
  /* eslint-enable no-param-reassign */

  axios.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    const account = msalInstance.getAllAccounts()[0];
    // eslint-disable-next-line no-mixed-operators
    if (error.response.status === 403 && !originalRequest._retry || error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });            
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  });

  return (
    <>
      {children}
    </>
  );
};

export default RequestInterceptor;