import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../config/auth/authConfig";
import { callMsGraph } from '../config/auth/graph'
import storage from '../utils/storage';


export function RequestAccessToken(instance: IPublicClientApplication, accounts: AccountInfo[]) {
    const request = {
        ...loginRequest,
        account: accounts[0]
    };


    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
        storage.setToken(response.accessToken)
        callMsGraph(response.accessToken).then(response => storage.setUser(response));
    }).catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
            callMsGraph(response.accessToken);
        });
    });
}