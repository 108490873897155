import { nanoid } from 'nanoid';
import create from 'zustand';

export type Error = {
  id: string;
  type: 'info' | 'warning' | 'success' | 'error';
  title: string;
  message?: string;
};

type ErrorStore = {
  errors: Error[];
  loading:boolean;
  addError: (error: Omit<Error, 'id'>) => void;
  dismissError: (id: string) => void;
  toggleLoading: (status: boolean) => void;
};

export const useErrorStore = create<ErrorStore>((set) => ({
  errors: [],
  loading: false,
  addError: (error) =>
    set((state) => ({
      errors: [...state.errors, { id: nanoid(), ...error }],
    })),
  dismissError: (id) =>
    set((state) => ({
      errors: state.errors.filter((error) => error.id !== id),
    })),
  toggleLoading: (status) => set(() => ({ loading: status})),
}));
