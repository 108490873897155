import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { MainLayout } from '../components/Layout'
import { Center, Loader, Stack, Title } from '@mantine/core';
import { lazyImport } from '../utils/lazyimport';

const { Registration } = lazyImport(() => import('../features/registration'), 'Registration');

const App = () => {
    
return (
            <MainLayout>
            <Suspense
                fallback={
                    <Center style={{height:'80vh'}}>
                        <Stack style={{alignItems:'center'}}>
                            <Loader size="lg" variant="bars" />
                            <Title order={3} style={{textAlign:'center'}}>Loading</Title>
                        </Stack>
                    </Center>
                }
            >
            <Outlet />
            </Suspense>
        </MainLayout>
        )
}

export const registrationRoute = [
    {
        path: '/registration',
        element: <App />,
            children: [
            { path: '/registration/', element: <Registration /> },
            { path: '*', element: <Navigate to="." /> },
            ],
    },
];