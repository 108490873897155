import { useQuery } from 'react-query';

import { axios } from '../../../library/axios';
import { ExtractFnReturnType, QueryConfig } from '../../../library/react-query';

export type Order = {
    'Aanmaak datum': string;
    'In gebruik': boolean;
    Magento: string;
    Referentie: string;
    Source: number;
    'Special Order': string;
};

export const getOrder = (reference: any): Promise<any> => {
    return axios.get(`/administration/order?reference=${reference}`);
};

type QueryFnType = typeof getOrder;

type useOrderOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useOrder = (reference: any) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      queryKey: ['orders'],
      queryFn: () => getOrder(reference),
    });
};