import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/auth/authConfig";
import { Button } from '@mantine/core'
import { IPublicClientApplication } from "@azure/msal-browser";
import {ReactComponent as MyLogo} from '../../../assets/svgs/myLogo.svg'
import {ReactComponent as MyLogoWhite} from '../../../assets/svgs/myLogoWhite.svg'

type SignInButtonProps = {
    buttonTitle: string;
}

function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest)
    .catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = ({buttonTitle} : SignInButtonProps) => {
    const { instance } = useMsal();

    return (
        <Button leftIcon={<MyLogoWhite/>} variant="filled" className="ml-auto LoginButton" mt="xl" size="md" style={{borderColor:'#ff5c8d', backgroundColor:'#ff5c8d', color:'#ffffff', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}} fullWidth onClick={() => handleLogin(instance)}>{buttonTitle}</Button>
    );
}