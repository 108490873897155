import { useRoutes } from 'react-router-dom'
import { useIsAuthenticated } from "@azure/msal-react";

import { registrationRoute } from './registrationRoute'
import { administrationRoutes } from './administrationRoute';
import { Login } from '../features/auth/routes/Login';
import { publicRoutes } from './public';
import storage from '../utils/storage';


export const AppRoutes = () => {
    const isAuthenticated = useIsAuthenticated();
    const LoggedInWithNav = storage.getNavToken();

    const commonRoutes = [{ path: '/', element: <Login /> }];

    const routes = (isAuthenticated) ? (administrationRoutes) : ((LoggedInWithNav === 'true') ? (registrationRoute) : (publicRoutes))

    
    const element = useRoutes([...routes, ...commonRoutes]);

    return <>{element}</>;
  };