import { graphConfig, loginRequest } from "./authConfig";
import storage from "../../utils/storage";
import { msalInstance } from "../..";
/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken: string) {    
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    storage.setToken(bearer);

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
