export const mapSource = (source: number) => {
    switch (source) {
        case 1:
            return 'Magento'
        case 2: 
            return 'Marketplaces'
    
        default:
            return 'Unknown'
            break;
    }
}