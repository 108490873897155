export const msalConfig = {
    auth: {
        clientId: '3635c004-d994-45b9-8277-cfcc8729f500',
        authority: 'https://login.microsoftonline.com/6c8ca006-4f17-48d3-acaa-62a7a1b50aff',
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: ["User.Read",]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}
