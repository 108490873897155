import { SignOutButton } from '../../../features/auth/components/signOutButton'
import storage from '../../../utils/storage'
import { Avatar, Button, Menu} from '@mantine/core'
import { IconLogout } from '@tabler/icons'
import { NavLink, useNavigate } from 'react-router-dom'
import {ReactComponent as MyLogo} from '../../../assets/svgs/myLogoHeader.svg'

export const TopHeader = () => {
    const user = storage.getUser()
    const navigate = useNavigate();

    const logoutNav = () => {
        storage.clearToken()
        storage.clearNavToken()
        navigate('/')
    }

    return (
        <div style={{padding:'5px 10px 0 10px', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <NavLink to='/'>
                <MyLogo style={{width:'80px', height:'auto'}}/>
            </NavLink>
            <Menu shadow="md" width={200}>
                <Menu.Target>
                    <Avatar radius="xl" />
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>{user? user.displayName: 'Logged in with navision'}</Menu.Label>

                    <Menu.Divider />
                    <Menu.Item color="red" icon={<IconLogout size={14} />}>{user ? <SignOutButton/> : <Button onClick={() => logoutNav()}>Log out</Button>}</Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </div>
    )
}