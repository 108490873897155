import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { MainLayout } from '../components/Layout'
import { Center, Loader, Stack, Title } from '@mantine/core';
import { lazyImport } from '../utils/lazyimport';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { RequestAccessToken } from '../library/auth';
import RequestInterceptor from '../library/interceptors/RequestInterceptor';

const { OrderOverview } = lazyImport(() => import('../features/administration'), 'OrderOverview');
const { OrderDetailView } = lazyImport(() => import('../features/administration'), 'OrderDetailView');

const App = () => {

  const {instance, accounts} = useMsal();
  
  useEffect(() => {
    RequestAccessToken(instance, accounts)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
return (
            <RequestInterceptor>
              <MainLayout>
                <AuthenticatedTemplate>
                  <Suspense
                      fallback={
                        <Center style={{height:'80vh'}}>
                            <Stack style={{alignItems:'center'}}>
                                <Loader size="lg" variant="bars" />
                                <Title order={3} style={{textAlign:'center'}}>Loading</Title>
                            </Stack>
                        </Center>
                      }>
                      <Outlet/>
                  </Suspense>
                  </AuthenticatedTemplate>
              </MainLayout>
            </RequestInterceptor>
        )
}

export const administrationRoutes = [
    {
      path: '/',
      element: <App />,
      children: [
        { path: '/administration', element: <OrderOverview /> },
        { path: '/administration/:detailid', element: <OrderDetailView /> },
        { path: '*', element:<OrderOverview /> },
        { path: '/', element:<OrderOverview /> },
      ],
    },
  ];