import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { queryClient } from '../library/react-query';

import { Loader, Button, Center, Stack, Title } from '@mantine/core';
import RequestInterceptor from '../library/interceptors/RequestInterceptor';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2>Ooops, something went wrong :( </h2>
      <Button onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
          <Center style={{height:'80vh'}}>
              <Stack style={{alignItems:'center'}}>
                  <Loader size="lg" variant="bars" />
                  <Title order={3} style={{textAlign:'center'}}>Loading</Title>
              </Stack>
          </Center>
      }
    >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <Router>{children}</Router>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
    </React.Suspense>
  );
};
