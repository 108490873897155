import { AppShell, Header, Navbar } from "@mantine/core";
import { ReactNode } from "react"
import {SideNavbar} from './SideNavbar'
import {TopHeader} from './TopHeader'

type MainLayoutProps = {
    children: ReactNode
}

export const MainLayout = ({children}: MainLayoutProps) => {
    
    return (
        <AppShell
            padding="md"
            // navbar={<Navbar width={{ base: 200 }} height={'90vh'} ><SideNavbar/></Navbar>}
            header={<Header height={60} p="xs"><TopHeader/></Header>}
            styles={(theme) => ({
                main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
            })}
            style={{height:'90vh'}}
    >
      {children}
    </AppShell>

    )
}