const storagePrefix = 'myj_';

const storage = {
  getToken: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}token`) as string);
  },
  getNavToken: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}nav_login`) as string);
  },
  setToken: (token: string) => {
    window.sessionStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  setUser: (token: any) => {
    window.sessionStorage.setItem(`${storagePrefix}user`, JSON.stringify(token));
  },
  getUser : () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}user`) as any)
  },
  setNavLoginToken: (token: string) => {
    window.sessionStorage.setItem(`${storagePrefix}nav_login`, JSON.stringify(token));
  },
  clearToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}token`);
  },
  clearNavToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}nav_login`);
  },

  clearUserToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}user`);
  },

  setFilters: (filters: any) => {
    const {paid_magento, paid_navision, special_order, zalando_order} = filters

    window.sessionStorage.setItem(`filters`, JSON.stringify({"paid_magento":paid_magento, "paid_navision": paid_navision, "special_order":special_order, "zalando_order" : zalando_order}))
  },

  getFilters: () => {
    return JSON.parse(window.sessionStorage.getItem(`filters`) as any)
  }

};

export default storage;
