import { mapSource } from "../../../utils/mapSource";
import storage from "../../..//utils/storage";
import { Alert, Button, Card, Center, Container, Loader, SimpleGrid, Stack, Table, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getNextOrder } from "../api/getNextOrder";
import { getOrder } from "../api/getOrder"
import { refundMagento } from "../api/refundMagento";
import { refundNavision } from "../api/refundNavision";
import Barcode from 'react-barcode'
import { showNotification } from "@mantine/notifications";
import { IconAlertCircle, IconArrowLeft, IconArrowRight, IconFileEuro, IconFileText } from "@tabler/icons";
import { Order } from "../../..//types";
import { updateStatus } from "../../registration/api/updateStatus";

export const OrderDetail = () => {
    const {detailid} = useParams()
    const [orderData, setOrderData] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [reloadOrder, setReloadOrder] = useState<boolean>(false)
    const [loadingNext, setLoadingNext] = useState<boolean>(false);
    const storedFilters = storage.getFilters();
    const navigate =  useNavigate()

    var orderDates = new Array<any>();

    useEffect(() => {
        getOrder(detailid)
        .then((res: any) => {

            setOrderData(res.data.RESULTS)
            setIsLoading(false)
        })
    
    }, [detailid, reloadOrder])

    if (orderData.length == 0) return null

    if(orderData.InUse) {
        return (
            <Container>
                <Stack>
                    <div>
                        Sorry, this order is already in use by: {orderData.InUse}
                    </div>
                    <Button component={Link} to={'./administration'}>Go back to the overview</Button>
                </Stack>
            </Container>
        )
    }

    orderData.forEach((item: any) => orderDates.push(item.created))

    var orderedDates = orderDates.sort(function(a, b) {
    return Date.parse(a) - Date.parse(b);
    });

    const current_user = storage.getUser();
    
    const goToNextOrder = () => {
        setLoadingNext(true)
        getNextOrder(orderedDates[0], storedFilters.paid_navision, storedFilters.paid_magento, storedFilters.special_order, storedFilters.zalando_order)
        .then(res => {
            if(res.status === 200 && res.data.RESULTS.length > 0){
                navigate(`/administration/${res.data.RESULTS[0].order_ref}`)
                showNotification({
                    title:'Redirecting',
                    message: 'Next order found',
                    color:'green'
                })
                setLoadingNext(false)
            } else {
                showNotification({
                    title:'Sorry',
                    message: 'No order found',
                    color:'orange'
                })
                setLoadingNext(false)
            }
            
        })   
    }

    const handleRefundMagento = (order: any) => {
        refundMagento({products:order, reference: detailid})
        .then(res => {
                if(res.status === 200){
                    setReloadOrder(!reloadOrder)
                    if (order[0]['RETURNLESS_ID']){
                        try {
                            updateStatus(
                                {
                                    "order_id": order[0]['RETURNLESS_ID'] ,
                                    "status_id": 'returnstatus_QGOMlY0Zy1bOwTMBVBZpaoCN'
                                }
                            )
            
                        } catch (e) {
                        console.log('Error')
                        }
             
                    }

                    showNotification({
                        title:'Success',
                        message:'Creditted in Magento',
                        color:'green'
                    })
                }
            })
    }

    const handleRefundNavision = (order: any) => {
        refundNavision({return_system: 'navision', reference: detailid, products:order, current_user: current_user.mail})
        .then((res: any) => {
            if(res.status === 200) {
                setReloadOrder(!reloadOrder)
                showNotification({
                    title:'Success',
                    message: 'Everything was set to credited in navision',
                    color:'green'
                })

            } else {
                showNotification({
                    title:'Something went wrong',
                    message: res.statusText,
                    color:'red'
                })
            }
        })
    }

    if (isLoading) {
        return (
            <Center style={{height:'80vh'}}>
                <Stack style={{alignItems:'center'}}>
                    <Loader size="lg" variant="bars" />
                    <Title order={3} style={{textAlign:'center'}}>Loading</Title>
                </Stack>
            </Center>
        );
    }

    
    if (orderData.length == 0) return null

    if(orderData.InUse) {
        return (
            <Container>
                <Stack>
                    <div>
                        Sorry, this order is already in use by: {orderData.InUse}
                    </div>
                    <Button component={Link} to={'./administration'}>Go back to the overview</Button>
                </Stack>
            </Container>
        )
    }

    return (
        <>
        {orderData && (
            <>
            <div className='orderdetail-navbar'>
                <div>
                    <Title order={3}>Order: {orderData[0]['order_ref']}</Title>
                </div>
                <div>
                    <div className='order-detail-toolbar-navigation'>
                        <div style={{justifyItems:'flex-end', alignItems:'flex-end'}}>
                                <Button leftIcon={<IconFileText/>} style={{marginRight:'10px',fontSize:'12px'}} onClick={() => {handleRefundMagento(orderData)}}>Credit all Magento</Button>
                                <Button leftIcon={<IconFileEuro/>} style={{fontSize:'12px', marginRight:'10px'}} onClick={() => {handleRefundNavision(orderData)}}>Credit all Navision</Button>
                        </div>
                        <div>
                            <Button leftIcon={<IconArrowLeft/>} component={Link} to={'/administration'} style={{marginRight:'10px', fontSize:'12px'}}>Return to overview</Button>
                            <Button leftIcon={<IconArrowRight/>} loading={loadingNext} style={{marginRight:'10px', fontSize:'12px'}} onClick={() => goToNextOrder()}>Go to next order</Button>
                        </div>
            </div>   
                </div>
            </div>
            <SimpleGrid cols={3}>
                <div style={{padding:'0 8px'}}>
                    <Stack>
                    Order ref:<Barcode width={2} height={60} fontSize={10} value={`${orderData[0]['order_ref']}`}/>
                    Pick nr:<Barcode width={2} height={60} fontSize={10} value={`${orderData[0]['pick_nr']}`}/>
                    </Stack>
                </div>
                <div></div>
                <Stack style={{padding:'8px'}}>
                    <Card>
                        <SimpleGrid cols={2}>
                            <Title order={5}>Creation date :</Title>
                            <div>{new Date(orderedDates[0]).toLocaleDateString().toString()}</div>
                        </SimpleGrid>

                        <SimpleGrid cols={2}>
                            <Title order={5}>Special case: </Title>
                            <div>{orderData[0].is_special_case_order == false ? 'No' : 'Yes'} </div>
                        </SimpleGrid>

                        <SimpleGrid cols={2}>
                            <Title order={5}>Source: </Title>
                            <div>{mapSource(orderData[0].source)} </div>
                        </SimpleGrid>

                        {orderData[0].source === 2 && (
                            <SimpleGrid cols={2}>
                            <Title order={5}>Ship to Country: </Title>
                                <div>{orderData[0].ship_to_country} </div>
                            </SimpleGrid>
                        )}
                        
                    </Card>
                    {orderData[0]['is_special_case_order'] && (
                    <Alert icon={<IconAlertCircle size={16} />} title="Attention!" color="pink">
                        THIS IS A SPECIAL ORDER!
                    </Alert>
                    )}

                </Stack>

            </SimpleGrid>

            <div style={{padding:'0 8px', marginTop:'50px'}}>
                    <Table striped highlightOnHover style={{background:'white'}}>
                    <thead>
                        <tr>
                            
                            <th style={{background:'#E3366A', color:'white'}}>EAN</th>
                            <th style={{background:'#E3366A', color:'white'}}>Item Nummer</th>
                            <th style={{background:'#E3366A', color:'white'}}>Beschrijving</th>
                            <th style={{background:'#E3366A', color:'white'}}>Foto</th>
                            <th style={{background:'#E3366A', color:'white'}}>Prijs</th>   
                            <th style={{background:'#E3366A', color:'white'}}>Variant</th>               
                            <th style={{background:'#E3366A', color:'white'}}>Kleur</th>
                            <th style={{background:'#E3366A', color:'white'}}>Reden</th>
                            <th style={{background:'#E3366A', color:'white'}}>Magento</th>
                            <th style={{background:'#E3366A', color:'white'}}>Navision</th>
                        </tr>
                    </thead>
                    <tbody>{orderData.map((element: Order, index:number) => (
                                <tr key={element.id ? element.id : index}>                                
                                    <td><Barcode width={1} height={60} fontSize={10} value={element.EAN}/></td>
                                    <td>{element['Item Nummer']}</td>
                                    <td>{element.Beschrijving}</td>
                                    <td><img className='zoom' src={element.Foto} alt='Product' style={{width:'50px'}}/><div className="underlay"></div></td>                            
                                    <td>{element.Prijs}</td>
                                    <td>{element.Variant}</td>
                                    <td>{element.Kleur}</td>
                                    <td>{element.Reden ? <Barcode width={0.4} height={60} fontSize={10} value={element.MAIN_REASON ? element.MAIN_REASON : element.Reden}/> : ''}</td>
                                    <td><Button disabled={element.paid_magento} onClick={() => handleRefundMagento([element])}>Magento credit</Button></td>
                                    <td><Button disabled={element.paid_navision} onClick={() => handleRefundNavision([element]) }>Navision credit</Button></td>
                                </tr>
                                ))}
                    </tbody>
                    </Table>
            </div>
        </>
        )}
        </>
    )
}