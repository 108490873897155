import { Order } from "../../../types";
import generateBadge from "../../../utils/createBadge";
import { mapSource } from "../../../utils/mapSource";
import { ActionIcon, Box, Button, Center, Loader, SimpleGrid, Skeleton, Space, Stack, Switch, Table, Text, TextInput, Title } from "@mantine/core";
import { IconAdjustments, IconCheck, IconSearch, IconX } from "@tabler/icons";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getOrders, getSingleOrder } from "../api/getOrders";
import storage from "../../../utils/storage";
import { showNotification } from "@mantine/notifications";

export const OrderOverviewTable = () => {
    const storedFilters = storage.getFilters();
    const initFilters = {
        "paid_magento" : storedFilters ? storedFilters.paid_magento : true,
        "paid_navision" : storedFilters ? storedFilters.paid_navision : false,
        "special_order" : storedFilters ? storedFilters.special_order : false,
        "zalando_order" : storedFilters ? storedFilters.zalando_order : false
    }

    const [reloadOrders, setReloadOrders] = useState<boolean>(false);
    const [filters, setFilters] = useState<any>(initFilters)
    const searchField = useRef<HTMLInputElement>(null);
    const [reference, setReference] = useState<string>('');
    
    const [orders, setOrders] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true); 
    const [filtersLoading, setFiltersLoading] = useState<boolean>(false); 

    const handleFilterChange = (value: any) => {
        setFiltersLoading(true)
        setFilters((prevState : any) => ({
                ...filters,   
                [value.name]: value.checked       
            }
        ))        
    }

    const searchOrder = () => {

        if (reference !== ''){
            setFiltersLoading(true)
            getSingleOrder(filters.paid_magento, filters.paid_navision, filters.special_order, filters.zalando_order, reference)
            .then(res => {
                if(res.data.RESULTS.length > 0){
                    setOrders(res.data.RESULTS)
                    setReference('')
                    if (null !== searchField.current) {
                        searchField.current.value = ''
                    }
                } else {
                    showNotification({
                        title:'Sorry',
                        message:'No order has been found',
                        color:'orange'
                    })
                }
                setFiltersLoading(false)
            }) 
        } else {
            setReloadOrders(true)
            return false        
        }
    }
    
    const handleEnter = (event:any) => {
        
        if (event.key === 'Enter') {
            setReference(event.currentTarget.value)
            searchOrder()
          }
    }

    const generateSkeleton = () => {
        let td:any[] = []
        for (let i = 1; i < 15; i++) {
            td.push(<tr key={i}>
                <td><Skeleton height={8} mt={6} radius="xl" /></td>
                <td><Skeleton height={8} mt={6} radius="xl" /></td>
                <td><Skeleton height={8} mt={6} radius="xl" /></td>
                <td><Skeleton height={8} mt={6} radius="xl" /></td>
                <td><Skeleton height={8} mt={6} radius="xl" /></td>
                <td><Skeleton height={8} mt={6} radius="xl" /></td>
                <td><Skeleton height={8} mt={6} radius="xl" /></td>
            </tr>);
        }
        return td;
    }

    const retrieveOrders = () => {
        setFiltersLoading(true)
        getOrders(filters.paid_magento, filters.paid_navision, filters.special_order, filters.zalando_order)
        .then(res => {
            setOrders(res.data.RESULTS)
            setIsLoading(false)
            setFiltersLoading(false)
            setReloadOrders(false)
            storage.setFilters(filters)
            setFiltersLoading(false)
        })
    }

    useEffect(() => {
        retrieveOrders()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, reloadOrders])
    
    
    if (isLoading) {
        return (
            <Center style={{height:'80vh'}}>
                <Stack style={{alignItems:'center'}}>
                    <Loader size="lg" variant="bars" />
                    <Title order={3} style={{textAlign:'center'}}>Loading</Title>
                </Stack>
            </Center>
        );
    }


    return (
        <>
        <Title order={3}>Administration</Title>
        <Text size="sm">Pick an order below to start</Text>
        <Space h="md" />
        <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', position:'relative'}} className='searchBar'>
            <TextInput 
                placeholder="Search reference"
                value={reference}
                ref={searchField}
                icon={<IconSearch size={14}
                style={{width:'100%'}} />}
                onChange={(event) => setReference(event.currentTarget.value)}
                onKeyDown={(event) => handleEnter(event)}
            />
            <Button onClick={searchOrder}>Search</Button>
            <Button variant='outline' onClick={retrieveOrders}>Reset</Button>
        </div>
        <Box sx={{background:'white', margin:'10px 0', padding:'15px'}}>
            <SimpleGrid cols={4}>
                <Switch
                    checked={filters.paid_magento}
                    onChange={(event) => handleFilterChange(event.currentTarget)}
                    color="#e3366a"
                    size="md"
                    name='paid_magento'
                    label="Hide paid in Magento"
                    thumbIcon={
                    filters.paid_magento ? (
                        <IconCheck size={12} color='#e3366a' stroke={3} />
                    ) : (
                        <IconX size={12} stroke={3} />
                    )
                    }
                />
                
                <Switch
                    checked={filters.paid_navision}
                    onChange={(event) => handleFilterChange(event.currentTarget)}
                    color="#e3366a"
                    size="md"
                    name='paid_navision'
                    label="Hide paid in Navision"
                    thumbIcon={
                        filters.paid_navision ? (
                        <IconCheck size={12} color='#e3366a' stroke={3} />
                    ) : (
                        <IconX size={12} stroke={3} />
                    )
                    }
                />
                <Switch
                    checked={filters.special_order}
                    onChange={(event) => handleFilterChange(event.currentTarget)}
                    color="#e3366a"
                    size="md"
                    name="special_order"
                    label="Show Special orders"
                    thumbIcon={
                        filters.special_order ? (
                        <IconCheck size={12} color='#e3366a' stroke={3} />
                    ) : (
                        <IconX size={12} stroke={3} />
                    )
                    }
                />
                <Switch
                    checked={filters.zalando_order}
                    onChange={(event) => handleFilterChange(event.currentTarget)}
                    color="#e3366a"
                    size="md"
                    name="zalando_order"
                    label="Zalando Orders"
                    thumbIcon={
                        filters.zalando_order ? (
                        <IconCheck size={12} color='#e3366a' stroke={3} />
                    ) : (
                        <IconX size={12} stroke={3} />
                    )
                    }
                />
            </SimpleGrid>
            
        </Box>
        {orders && (
            <Table striped highlightOnHover>
            <thead>
                <tr>
                    <th style={{background:'#E3366A', color:'white'}}>Referentie</th>
                    <th style={{background:'#E3366A', color:'white'}}>Aanmaak Datum</th>
                    <th style={{background:'#E3366A', color:'white'}}>Magento</th>
                    <th style={{background:'#E3366A', color:'white'}}>Navision</th>
                    <th style={{background:'#E3366A', color:'white'}}>Special order</th>
                    <th style={{background:'#E3366A', color:'white'}}>Source</th>
                    <th style={{background:'#E3366A', color:'white'}}>Action</th>
                </tr>
            </thead>
            <tbody>
                {filtersLoading && (
                        generateSkeleton()
                    )
                }
                
                {!filtersLoading && (
                    orders.map((element: any, index:number) => (
                        <tr key={element.Referentie ? element.Referentie : index}>
                            <td>{element.Referentie}</td>
                            <td>{element["Aanmaak datum"]}</td>
                            <td>{generateBadge(element.Magento)}</td>
                            <td>{generateBadge(element.Navision)}</td>
                            <td>{generateBadge(element["Special order"])}</td>
                            <td>{mapSource(element.Source)}</td>
                            <td><Button variant="light" disabled={element['In gebruik']} component={Link} to={`/administration/${element.Referentie}`}>Go to order</Button></td>
                        </tr>
                        ))
                )}

            </tbody>
            </Table>
        )}
        </>
    )
}