import { lazyImport } from '../utils/lazyimport';

const { AuthRoutes } = lazyImport(() => import('../features/auth'), 'AuthRoutes');

export const publicRoutes = [
  {
    path: '/*',
    element: <AuthRoutes />,
  },
];
