
import { OrderDetail } from '../components/orderDetail';

export function OrderDetailView() {

  return (
    <>
      <OrderDetail/>
    </>
  );
}