import { showNotification } from '@mantine/notifications';

import Axios, { AxiosRequestConfig } from 'axios';

import storage from '../utils/storage';
import { EnvironmentVariablesProvider } from '../config';
import { useErrorStore } from '../stores/axiosStore';

const environmentVariablesProvider = new EnvironmentVariablesProvider();

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    if (config.headers === undefined) {
        config.headers = {};
    }
        config.headers.authorization = `${token}`;
        config.headers.Accept = 'application/json';
  }
  
  return config;
}

export const axios = Axios.create({
  baseURL: environmentVariablesProvider.getVariable('api_url')
});


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    showNotification({
      title: 'There has been an error',
      message: message,
    })

    useErrorStore.getState().toggleLoading(false)
    
    useErrorStore.getState().addError({
      type: 'error',
      title: 'Error',
      message,
    });

    return Promise.reject(error);
  }
);