import { useQuery } from 'react-query';

import { axios } from '../../../library/axios';
import { ExtractFnReturnType, QueryConfig } from '../../../library/react-query';

export type Order = {
    'Aanmaak datum': string;
    'In gebruik': boolean;
    Magento: string;
    Referentie: string;
    Source: number;
    'Special Order': string;
};

export const getOrders = (paidMagento : boolean, paidNavision : boolean, specialCase:boolean, zalando_order:any): Promise<any> => {
    return axios.get(`/administration/orders?paid_navision=${paidNavision ? 1 : 0}&paid_magento=${paidMagento ? 1 : 0}&special-cases=${specialCase ? 1 : 0}&non-special-cases=${specialCase ? 0 : 1}&zalando_orders=${zalando_order ? 1 : 0}`);
};

export const getSingleOrder = (paidMagento : boolean, paidNavision : boolean, specialCase:boolean, zalando_order:any, reference: any): Promise<any> => {
    return axios.get(`/administration/orders?reference=${reference}`);
};

type QueryFnType = typeof getOrders;

type useOrderOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useOrders = (paidMagento : boolean, paidNavision : boolean, specialCase:boolean, zalando_order:boolean) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      queryKey: ['orders'],
      queryFn: () => getOrders(paidMagento, paidNavision, specialCase, zalando_order),
    });
};