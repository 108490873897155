import { useState } from 'react';
import {
  Box, 
  LoadingOverlay,
  Paper,
  createStyles,
  Image,
  Title,
  Text,
  Grid,
  Button,
  Center,
  Container,
  Divider,
  TextInput,
} from '@mantine/core';

import { SignInButton } from './signInButton';

import loginBracelet from '../../../assets/loginbracelet.png'

import { UnauthenticatedTemplate } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import {loginNav} from '../api/loginNav'
import { useForm } from '@mantine/form';
import storage from '../../../utils/storage';
import { showNotification } from '@mantine/notifications';


const useStyles = createStyles((theme) => ({
  wrapper: {
    background: `url(${loginBracelet})`,
    backgroundSize:'cover',
    maxWidth:'100%',
    maxHeight:'100%',
    top: 0,
    left: 0,
    boxSizing:'border-box',
    backgroundPosition:'center'
  },

  form: {
    // borderRight: `1px solid ${
    //   theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    // }`,
    minHeight: '100vh',
    maxWidth: '100%',
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    width: 80,
    display: 'block',
    margin:10,
    padding:'0px 10px 10px 10px ',
    height:'auto',
  },

  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  loginImage: {
    maxWidth:'100%',
  }
}));


export function LoginScreen() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const form = useForm({
    initialValues: {
      username: '',
      pwd: ''
    }
  });

  const handleLogin = (values: { username: string; pwd: string; }) => {
    setIsLoading(true)
      loginNav(values.username, values.pwd).then((resp:any) =>{     
        if(resp.data.authenticated === true){
          storage.setNavLoginToken('true');
          sessionStorage.setItem('user', resp.data.user);
          sessionStorage.setItem('user_name', resp.data.user_name);
          navigate('/registration');
          setIsLoading(false);
        } else {
          showNotification({
            title:'Something went wrong',
            message:'Please try again or contact IT',
            color:'red',
          })
          setIsLoading(false);
        }    
      })
    setTimeout(() => {
      setIsLoading(false)
    }, 2500);
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <Container size='xl' p='md' style={{backgroundColor:'white', marginTop:'50px', maxHeight:'85vh', padding:'0', boxShadow:'rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 10px 15px -5px, rgb(0 0 0 / 4%) 0px 7px 7px -5px'}}>
          <Grid style={{minHeight:'85vh'}}>

            <Grid.Col span={6}>
                  <div className='hi' style={{display:'flex', justifyContent:'left'}}>
                    <Image className={classes.logo} style={{width:'80px !important'}} src={'https://www.my-jewellery.com/static/version1657606621/frontend/WeProvide/MyJewellery/nl_NL/images/logo.svg'}/>
                  </div>
                  <Center style={{height: '90%'}}>
                    <Paper>
                      <Title order={2}>Welcome to the Retouren Portal</Title>
                      <Text size="md">Please login to continue</Text>
                      <SignInButton buttonTitle="Login with My Jewellery account" />
                      <div style={{margin:'30px 0'}}>
                        <Divider my="xs" label="or" labelPosition="center" />
                      </div>
                      <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                        <Box sx={{
                          maxWidth:'400',
                          position:'relative'
                        }}>
                          <LoadingOverlay visible={isLoading} overlayBlur={2} />
                            <form onSubmit={form.onSubmit((values) => (handleLogin(values)))}>
                            <TextInput
                              placeholder="NAVISION Username"
                              style={{marginBottom:'12px'}}
                              required
                              {...form.getInputProps('username')}
                            />
                            <TextInput
                              placeholder="NAVISION Password"
                              type='password'
                              required
                              {...form.getInputProps('pwd')}
                            />
                            <Button loading={isLoading} variant='outline' style={{marginTop:'12px', backgroundColor:'#ff5c8d', color:'white', borderColor:'transparent'}} fullWidth type='submit'>Login with NAVISION</Button>

                          </form>
                        </Box>
                       
                      </div>
                    </Paper>
                  </Center>
            </Grid.Col>

            <Grid.Col span={6} className={classes.wrapper} />
          </Grid>
        </Container>
      </UnauthenticatedTemplate>
    </>
  );
}