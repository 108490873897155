
import { OrderOverview } from "../../../features/administration";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { LoginScreen } from "../components/Layout";

export const Login = () => {

    return (
        <>
            <AuthenticatedTemplate>
                <OrderOverview/>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginScreen/>
            </UnauthenticatedTemplate>
        </>
    )
}