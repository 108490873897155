import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './config/auth/authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider
       theme={{
        colors: {
          brand: [
            "#F7D7E1",
            "#F4B0C4",
            "#F787A9",
            "#FF5C8D",
            "#F2467A",
            "#E3366A",
            "#D22B5D",
            "#B33158",
            "#9A3352",
            "#84344C"
          ]
          
        },
        primaryColor: 'brand',
        primaryShade:4,
      }}
    >
      
        <ModalsProvider>
          <NotificationsProvider position="top-right" zIndex={2077}>
            <MsalProvider instance={msalInstance}>
                <App/>
            </MsalProvider>
          </NotificationsProvider>
        </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();