import { Badge } from "@mantine/core"

const baseElement = (color:string, status:string) => {
    return <Badge style={{width:'180px'}} color={color} size="md" radius="xs">{status}</Badge>
}

const generateBadge = (status: string) => {
    switch (status) {
        case 'Gecrediteerd':
                return baseElement('green', status)
  
        case 'Niet gecrediteerd':
                return baseElement('orange', status)

        case 'Ja':
            return baseElement('indigo', status)
  
        default:
                return baseElement('red', status)
            break;
    }
}

export default generateBadge;