import { useQuery } from 'react-query';

import { axios } from '../../../library/axios';
import { ExtractFnReturnType, QueryConfig } from '../../../library/react-query';
import { Order, AxiosResponse } from '../../../types';

export const getNextOrder = (date:string, paidNavision:any, paidMagento:any, specialCase:any, zalando_order:any): Promise<AxiosResponse> => {
    // return axios.get(`/administration/order/next?current-created-date=${date}`)
    return axios.get(`/administration/order/next?paid_navision=${paidNavision ? 1 : 0}&paid_magento=${paidMagento ? 1 : 0}&special-cases=${specialCase ? 1 : 0}&non-special-cases=${specialCase ? 0 : 1}&zalando_orders=${zalando_order ? 1 : 0}&current-created-date=${date}`)
}


type QueryFnType = typeof getNextOrder;

type useOrderOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useNextOrder = ((date:string, paidNavision:any, paidMagento:any, specialCase:any, zalando_order:any) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      queryKey: ['orders'],
      queryFn: () => getNextOrder(date, paidNavision, paidMagento, specialCase, zalando_order),
    });
});